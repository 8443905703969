async function submitEmail(email) {
  if (!email) {
    throw new Error('Email is empty.');
  }

  const request = new Request(
    'https://e409cjpqp7.execute-api.us-east-1.amazonaws.com/default/EmailCollector',
    {
      method: 'PUT',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    }
  );

  return fetch(request);
}

function getOutput(form) {
  return form.querySelector('output');
}

class SignupFormElement extends HTMLElement {
  get fallbackHref() {
    return this.getAttribute('fallback-href');
  }

  set fallbackHref(val) {
    this.setAttribute('fallback-href', val);
  }

  get successMessage() {
    return this.getAttribute('success-message');
  }

  get buttonLabel() {
    return this.getAttribute('button-label');
  }

  get emailLabel() {
    return this.getAttribute('email-label');
  }

  constructor() {
    super();

    this.root = this.attachShadow({ mode: 'open' });

    this.root.innerHTML = `
    <style>
      :host {
        display: block;
      }

      slot[name='success'] {
        display: none;
      }

      :host([successful]) slot:not([name]) {
        display: none;
      }

      :host([successful]) slot[name='success'] {
        display: inline;
      }
    </style>
    <slot></slot>
    <slot name="success"></slot>
    `;

    this.innerHTML = `
    <output hidden aria-live="polite"></output>

    <form novalidate>
      <p>
        <label for="email" required>${this.emailLabel}</label>
        <input id="email" type="email">
      </p>

      <p class="u-visually-hidden">
        <label for="ningenkakunin">Please leave this field blank.</label>
        <input id="ningenkakunin">
      </p>

      <div class="u-text-center">
        <button class="u-full-width">${this.buttonLabel}</button>
      </div>
    </form>

    <p slot="success" aria-live="polite">${this.successMessage}</p>
    `;
  }

  connectedCallback() {
    this.addEventListener('submit', event => {
      event.preventDefault();

      const email = event.target.elements.email;
      const humanCheck = event.target.elements.ningenkakunin;

      if (humanCheck.value) {
        this.showError(`Oops, you triggered our spambot detector. Please enter only your email address.`);
        return;
      }

      if (email.value.length === 0 || (email.validity && !email.validity.valid)) {
        this.showError(`Please enter a valid email address.`);
        return;
      }

      try {
        submitEmail(email.value).then(() => {
          this.showSuccess();
        }).catch(error => {
          this.showError(`Something went wrong! If the problem persists, try <a href="${this.fallbackHref}">this form</a> instead.`);
        });
      } catch (error) {
        this.showError(`Something went wrong! If the problem persists, try <a href="${this.fallbackHref}">this form</a> instead.`);
      }
    });
  }

  showSuccess() {
    this.setAttribute('successful', '');
  }

  showError(message) {
    const el = getOutput(this);
    el.innerHTML = message;
    el.hidden = false;
  }
}

if ('customElements' in window) {
  customElements.define('signup-form', SignupFormElement);
}
